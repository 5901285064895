"use client";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  argentWallet,
  coinbaseWallet,
  ledgerWallet,
  metaMaskWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { createConfig, http } from "wagmi";
import {
  mainnet,
  polygon,
  bsc,
  bscTestnet,
  fantom,
  optimism,
  fantomTestnet,
  arbitrum,
} from "wagmi/chains";

const projectId = process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID
  ? process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID
  : "e253833d8c50f51c88fea1e2749cfafe";
const isProdEnv = process.env.NEXT_PUBLIC_NODE_ENV === "production";

if (!projectId) {
  throw new Error("WalletConnect Project ID is not defined");
}

const connectors = connectorsForWallets(
  [
    {
      groupName: "Other",
      wallets: [
        metaMaskWallet,
        rainbowWallet,
        walletConnectWallet,
        ledgerWallet,
        rabbyWallet,
        coinbaseWallet,
        argentWallet,
        safeWallet,
      ],
    },
  ],
  {
    appName: "Finexim-Bank",
    projectId: projectId,
    appUrl: process.env.REDIRECT_URI,
  }
);

const customFantom = { ...fantom, iconUrl: "/images/favcion.png" };
// const customfantomTestnet = {
//   ...fantomTestnet,
//   iconUrl: "/images/favcion.png",
// };

const transports = isProdEnv
  ? {
      [mainnet.id]: http(),
      [polygon.id]: http(),
      [optimism.id]: http(),
      [arbitrum.id]: http(),
      [fantom.id]: http(),
      [bsc.id]: http(),
    }
  : {
      // [fantomTestnet.id]: http(),
      [bscTestnet.id]: http(),
    };

export const wagmiConfig = createConfig({
  chains: isProdEnv
    ? [mainnet, polygon, optimism, arbitrum, customFantom, bsc]
    : [bscTestnet],
  connectors,
  transports,
  ssr: false,
});
