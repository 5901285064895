import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "src/router";
import AuthContext from "src/context/Auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import { Buffer } from "buffer";
import { WagmiProvider } from "wagmi";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import _ from "lodash";
import "@rainbow-me/rainbowkit/styles.css";
import { wagmiConfig } from "src/utils/wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const myTheme = _.merge(darkTheme(), {
  colors: {
    accentColor: "#07296d",
  },
});
function App() {
  const theme = createTheme();
  const queryClient = new QueryClient();
  const appInfo = {
    appName: "Finexim-Bank",
  };
  global.Buffer = Buffer;

  return (
    <div className="App">
      {" "}
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{ style: { fontFamily: "Roboto Condensed" } }}
              />
              <RainbowKitProvider appInfo={appInfo} theme={myTheme}>
                <AuthContext>
                  <RouterProvider router={router} />
                </AuthContext>
              </RainbowKitProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </div>
  );
}

export default App;
