import { getAPIHandler, getDataHandlerAPI } from "src/ApiConfig/service";
import * as XLSX from "xlsx";
import { PublicKey } from "@solana/web3.js";
export const stakingContract = "0x050e0b587908707C267696E5A985b9D7f740b926";
export const rewardContract = "0x6Cc4344A5E02e6498364cCcB377e641a43C48bF7";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}
export function formatToComaNumberingSystem(number) {
  const numberString = parseFloat(number).toString();
  const [integerPart, decimalPart] = numberString.split(".");

  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedInteger =
    otherDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (otherDigits !== "" ? "," : "") +
    lastThreeDigits;

  return decimalPart > 0
    ? formattedInteger + "." + decimalPart
    : formattedInteger;
}
export const convertArrayToXLSX = async (apiArray, fileName) => {
  try {
    const flattenedArray = apiArray.map((payment) => flattenObject(payment));
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(flattenedArray);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    // Convert the binary string to a typed array
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length); // Create a buffer
      const view = new Uint8Array(buf); // Create a view
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; // Convert
      return buf;
    };

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const downloadURL = URL.createObjectURL(blob);
    // Add timestamp to the filename
    const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
    const timestampedFileName = `${fileName}_${timestamp}.xlsx`;
    await downloadFileAsync(downloadURL, timestampedFileName);
  } catch (error) {
    console.error("Error:", error);
  }
};
const toReadableFormat = (key) => {
  return key
    .replace(/([A-Z])/g, " $1") // Add a space before capital letters
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
};

// Utility function to flatten an object with dynamic key mapping
const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res);
    } else {
      let readableKey = parent ? toReadableFormat(key) : toReadableFormat(key);
      res[readableKey] = obj[key];
    }
  }
  return res;
};
async function downloadFileAsync(url, fileName) {
  try {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.style.display = "none";
    document.body.appendChild(a);

    return new Promise((resolve, reject) => {
      a.click();
      resolve();
    }).then(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url); // Clean up
    });
  } catch (error) {
    console.error("Error:", error);
  }
}

export function checkNumber(value) {
  // const re = /^(?!0+$)[0-9]{1,10}$/gm;
  // return re.test(value);
  const re = /^[1-9][0-9]{9}$/;
  return re.test(value);
}
export function replacetext(text, replaceTo, replaceWith) {
  // Replace '-' with ' ' and split the string into words
  const words = text
    .split(replaceTo)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words with space
  return words.join(replaceWith);
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const getFolderListDataApi = async (
  source,
  page,
  folderType,
  search
) => {
  try {
    const response = await getAPIHandler({
      endPoint: "listFolder",
      source: source,
      paramsData: {
        folderType: folderType,
        // page: page,
        // limit: "10",
        // sort: "ALPHA",
        // search: search,
      },
    });
    if (response.data.responseCode === 200) {
      return response.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export function fixDecimal(number) {
  return number ? formatToComaNumberingSystem(number) : 0;
  // const zeroCount = countZerosAfterDecimal(number);

  // if (zeroCount === 0 && number === Math.floor(number)) {
  //   return number.toString();
  // } else if (zeroCount === 0 || number >= 1) {
  //   return parseFloat(number).toFixed(4);
  // } else if (number < 1) {
  //   return parseFloat(number)
  //     .toFixed(zeroCount + 4)
  //     .toString();
  // }
}

export function countZerosAfterDecimal(number) {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return 0;
  }

  let zeroCount = 0;
  for (let i = decimalIndex + 1; i < numString.length; i++) {
    if (numString[i] === "0") {
      zeroCount++;
    } else {
      break;
    }
  }
  return zeroCount;
}

export const formatKey = (key) => {
  return key
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};

export function generateRandomName() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const nameLength = Math.floor(Math.random() * (8 - 5 + 1)) + 3;
  let randomName = "";

  for (let i = 0; i < nameLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const character = characters.charAt(randomIndex);

    if (i === 0) {
      randomName += character.toUpperCase();
    } else {
      randomName += character;
    }
  }

  return randomName;
}

export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};

export const listUserHandlerExcel = async ({ paramsData, endPoint }) => {
  try {
    const res = await getDataHandlerAPI({
      endPoint: endPoint,
      paramsData: {
        page: 1,
        ...paramsData,
      },
    });
    if (res.data.responseCode === 200) {
      return res.data.result.docs;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const validateAccountAddress = async (account) => {
  try {
    new PublicKey(account);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value.slice(0, selectionStart) + value.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};
