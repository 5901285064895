import React, { useEffect, useState, useContext, useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaUser, FaUsers, FaWallet } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { BsQuestionCircle } from "react-icons/bs";
import { SlBadge } from "react-icons/sl";
import { MdOutlineTableRows } from "react-icons/md";
import PropTypes from "prop-types";
import { IoPersonOutline } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";
import { FaUserCheck } from "react-icons/fa";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { AiOutlineLogout } from "react-icons/ai";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-hot-toast";
import { RxStack } from "react-icons/rx";
import ConfirmationModal from "src/component/ConfirmationModal";
import { getAPIHandler } from "src/ApiConfig/service";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { CgProfile } from "react-icons/cg";
import { RxDashboard } from "react-icons/rx";
import { MdLogout } from "react-icons/md";
import { CiBank } from "react-icons/ci";

export const sections = [
  {
    items: [
      {
        title: "Sub Admin Management",
        modules: "dashboard",
        icon: SupervisorAccountIcon,
        href: "/sub-admin-management",
      },
      {
        title: "User Management",
        modules: "dashboard",
        icon: FaUserCheck,
        href: "/user-management",
      },
      {
        title: "KYC Management",
        modules: "dashboard",
        icon: RxStack,
        href: "/kyc-management",
      },
      {
        title: "Wallet Management",
        modules: "dashboard",
        icon: FaWallet,
        href: "/wallet-listing",
      },
      // {
      //   title: "Fee Management",
      //   modules: "dashboard",
      //   icon: TbMoneybag,
      //   href: "/fee-management",
      // },
      {
        title: "Transaction Management",
        modules: "dashboard",
        icon: GrTransaction,
        href: "/transaction-listing",
      },
      {
        title: "Ticket Management",
        modules: "dashboard",
        icon: BsQuestionCircle,
        href: "/ticket-management",
      },
      {
        title: "Static Content Management",
        modules: "dashboard",
        icon: MdOutlineTableRows,
        href: "/static-management",
      },
      {
        title: "Deal Income Management",
        modules: "dashboard",
        icon: SlBadge,
        href: "/reward-management",
      },
      {
        title: "Bank Overview",
        modules: "dashboard",
        icon: CiBank,
        href: "/bank-overview",
      },
    ],
  },
];

export const subAdmin = [
  {
    items: [
      {
        title: "Dashboard",
        modules: "dashboard",
        icon: RxDashboard,
        href: "/dashboard",
      },
      // {
      //   title: "User Management",
      //   modules: "dashboard",
      //   icon: FaUser,
      //   href: "/user-management",
      // },
      {
        title: "My Account",
        modules: "dashboard",
        icon: IoPersonOutline,
        href: "/account",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "rgba(14, 14, 17, 1)",
  },
  desktopDrawer: {
    top: "76px",
    width: "260px",
    height: "calc(100% - 76px)",
    background: "rgba(14, 14, 17, 1)",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    marginLeft: "0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },

  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  let connectedExchange = auth.userData?.connectedExchange?.length;
  const history = useNavigate();
  const [isLogout, setIsLogout] = useState(false);

  const logoutFunction = () => {
    toast.success("You have been logout successfully!");
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("packman");
    auth.userLogIn(false, null);
    setIsLogout(false);
    history("/");
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const checkArray = useMemo(() => {
    const matchedItems = sections.flatMap((section) =>
      section.items.filter(
        (item) =>
          auth?.userData?.permissions &&
          auth?.userData?.permissions.includes(item.title)
      )
    );
    return auth?.userData?.userType != "ADMIN"
      ? [...subAdmin, { items: matchedItems }]
      : [...subAdmin, ...sections];
  }, [auth?.userData?.permissions]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      paddingY="10px"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {checkArray &&
              checkArray?.map((section, i) => {
                return (
                  <List
                    key={`menu${i}`}
                    style={{ padding: "0px 10px" }}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {section.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      img: section.img,
                      items: section.items,
                      pathname: location.pathname,
                    })}
                  </List>
                );
              })}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <Button
              fullWidth
              style={{ display: "flex", justifyContent: "start" }}
              onClick={() => setIsLogout(true)}
            >
              <MdLogout color="red" fontSize="20px" />
              <span style={{ marginLeft: "20px" }}>Logout</span>
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>

      {isLogout && (
        <ConfirmationModal
          open={isLogout}
          isLoading={false}
          handleClose={() => {
            setIsLogout(false);
          }}
          title={"Logout"}
          desc={"Are you sure you want to logout?"}
          handleSubmit={(item) => logoutFunction(item)}
        />
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
