import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { Autocomplete } from "@material-ui/lab";
import { IoClose } from "react-icons/io5";

export default function ConfirmationModal({
  title,
  desc,
  isLoading,
  open,
  handleClose,
  handleSubmit,
  type,
  filter,
  setFilter,
  auth,
  error,
  handleBlur,
  isSubmit,
}) {
  const status = [
    {
      label: "REJECT",
      value: "REJECT",
    },
    {
      label: "APPROVE",
      value: "APPROVE",
    },
  ];
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Box align="center" mt={3}>
          <Typography variant="h4" style={{ color: "#FFFFFF" }}>
            {title}
          </Typography>
        </Box>
        <Box align="center" mt={1} mb={2}>
          <Typography variant="body2">{desc}</Typography>
        </Box>
        {type === "reason" && (
          <>
            <Box mb={2}>
              <Autocomplete
                fullWidth
                disableClearable={true}
                value={filter.status}
                onChange={(event, value) => {
                  setFilter({
                    ...filter,
                    status: value,
                  });
                }}
                options={(status && status.map((option) => option.label)) || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Status"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Box>
            {filter?.status == "REJECT" && (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  multiline
                  className="textField"
                  rows={5}
                  placeholder="Type something..."
                  value={filter?.reason}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      reason: e.target.value,
                    });
                  }}
                />
                <Box className="displaySpacebetween" mt={1}>
                  <FormHelperText error>{isSubmit && error}</FormHelperText>
                  <Typography variant="body1" textAlign="end">
                    {filter?.reason?.length}/600
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
        <Box my={3} align="center">
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={() => {
              filter?.status == "APPROVE" && !auth.walletAddress
                ? auth.isWalletConnected()
                : handleSubmit();
            }}
            style={{ marginRight: "8px" }}
          >
            {filter?.status == "APPROVE" && !auth.walletAddress ? (
              "Connect Wallet"
            ) : isLoading ? (
              <ButtonCircularProgress />
            ) : (
              "Yes"
            )}
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            color="secondary"
            onClick={() => {
              if (!isLoading) {
                handleClose();
              }
            }}
            style={{ marginLeft: "8px" }}
          >
            No
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
