import { AppBar, Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky",
    bottom: 0,
    zIndex: 1000,
    top: "auto",
    height: "25px",
    textAlign: "center",
    padding: "25px 0px",
    background: "rgb(19 34 39)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .bottomText": {
      margin: "-8px",
    },
  },
}));
function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.appBar}>
      <Box align="center" className="bottomText">
        <Typography
          variant="body2"
          color="secondary"
          style={{ fontSize: "14px" }}
          className="satoshiRegularlight"
        >
          &copy;2024 All Right Reserved
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
