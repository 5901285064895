import CryptoJS from "crypto-js";
const base64url = require("base64url");

const secret1 = process.env.REACT_APP_SECRET1_KEY;
const secret2 = "JnAdWuHJVSkMQI7omr5zWGZLbVxosfN4rHThiNtu9mQ";

export const encryptJSION = (jsionText) => {
  // let iv = JSON.stringify(jsionText);
  // const encryptedIv = CryptoJS.AES.encrypt(iv, secret2);
  // return encryptedIv.toString();
  const iv = CryptoJS.lib.WordArray.random(16); // Random Initialization Vector
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(jsionText),
    CryptoJS.enc.Base64.parse(secret2),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // Concatenate IV and ciphertext, then encode as base64url
  const ivAndCipherText = iv.concat(encrypted.ciphertext);
  return base64url.fromBase64(ivAndCipherText.toString(CryptoJS.enc.Base64));
};

export const decryptJSION = (cipherText) => {
  try {
    // const iv = CryptoJS.AES.decrypt(encryptedIv, secret2);
    // let jsionValue = iv.toString(CryptoJS.enc.Utf8);
    // return JSON.parse(jsionValue);
    const cipherTextBytes = base64url.toBuffer(cipherText);
    const iv = CryptoJS.lib.WordArray.create(cipherTextBytes.slice(0, 16));
    const encrypted = CryptoJS.lib.WordArray.create(cipherTextBytes.slice(16));

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encrypted },
      CryptoJS.enc.Base64.parse(secret2),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    console.log("error", err);
    return false;
  }
};
