export const RPC = "https://api.devnet.solana.com";

export const Network = "Binance Smart Chain testnet";
const nativeCurrency = {
  name: "BNB",
  symbol: "BNB",
  decimals: 18,
};

export const NetworkContextName = Network;
export const ACTIVE_NETWORK = 97;
export const RPC_URL = "https://bsc-testnet-rpc.publicnode.com	"; //

export const NetworkDetails = [
  {
    chainId: "0x1068",
    chainName: NetworkContextName,
    nativeCurrency: nativeCurrency,
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://scan.merlinchain.io"],
  },
];

export const swichNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    console.log("ERROR", error);
    if (error.code === 4902) {
      addNetworkHandler();
    }
  }
};

export const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};
